$(document).ready(function() {
	$.ajaxSetup({
		headers: {
			"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
		},
	});

	// $(this).scrollTop(0);

	initHomeBanner();
	initInnerBanner();
	initMobileMenu();
	initAdsSlider();

	imgToBG($(".bg-img"), ".img-bg");

	stickyHeader();

	$(".venobox").venobox({
		// closeColor: "transparent",
		// closeBackground: "transparent",
		titlePosition: "bottom",
	});

	initNewsletterListener();
	initContactFormListener();

	reSizeCaptcha();

	AOS.init();
});

$(window).on('scroll', stickyHeader);

$(window).on("beforeunload", function() {
	// $(window).scrollTop(0);
});

$(window).on("load", function() {
	$("body").on("submit", "form", function(ev) {
		// setButtonLoading($(this));
	});

	if ($(".is-invalid").length) {
		$("html, body").animate(
			{
				scrollTop:
					$(".is-invalid")
						.first()
						.offset().top - 100,
			},
			1000
		);
	} else if ($(".alert.alert-danger").length) {
		$("html, body").animate(
			{
				scrollTop:
					$(".alert.alert-danger")
						.first()
						.offset().top - 100,
			},
			1000
		);
	}

	$("#backToTop").on("click", function(ev) {
		ev.preventDefault();

		$("html, body").animate(
			{
				scrollTop: 0,
			},
			500
		);
	});

	$("#searchModal").on("shown.bs.modal", function() {
		$("#keyword").trigger("focus");
	});

	// $(".modal").on("show.bs.modal", function() {
	// 	bodyScrollLock.disableBodyScroll($(this)[0]);
	// });

	// $(".modal").on("hide.bs.modal", function() {
	// 	bodyScrollLock.enableBodyScroll($(this)[0]);
	// });
});

function initHomeBanner() {
	if ($("#banner").length) {
		var homeBanner = new Swiper("#banner", {
			autoplay: {
				delay: 5000,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			// pagination: {
			// 	el: ".swiper-pagination",
			// 	type: "bullets",
			// },
		});
	}
}

function initInnerBanner() {
	if ($("#innerBanner").length) {
		var innerBanner = new Swiper("#innerBanner", {
			autoplay: {
				delay: 5000,
			},
		});
	}
}

function stickyHeader() {
	if ($(window).scrollTop() > $("#header").innerHeight()) {
		$("#header").addClass("sticky");
		$("#logo").addClass("sticky");
		$("#navigation").addClass("sticky");
	} else {
		$("#header").removeClass("sticky");
		$("#logo").removeClass("sticky");
		$("#navigation").removeClass("sticky");
	}
}

function initMobileMenu() {
	var mmenu = new MmenuLight($("#mobileMenu").get(0), {
		title: "Menu",
		selected: "active",
	});

	mmenu.enable("(max-width: 991px)");
	mmenu.offcanvas({
		position: "right",
		move: true,
		blockPage: true,
	});

	$("#mobileMenu").addClass("mm");

	$('a[href="#mobileMenu"]').on("click", function(ev) {
		mmenu.open();

		ev.preventDefault();
		ev.stopPropagation();
	});
}

function initAdsSlider() {
	if ($("#ads").length) {
		var adsSwiper = new Swiper("#ads.swiper-container", {
			autoplay: {
				delay: 5000,
			},
		});
	}
}

function imgToBG(bgClass, imageClass) {
	if (bgClass.length > 0) {
		bgClass.each(function() {
			var img =
				$(this)
					.find(imageClass)
					.eq(0) ||
				$(this)
					.find("img")
					.eq(0);

			$(this).css({
				"background-image": 'url("' + img.attr("src") + '")',
			});

			img.hide();
		});
	}
}

function isValidEmailAddress(emailAddress) {
	var pattern = new RegExp(
		/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
	);
	return pattern.test(emailAddress);
}

function initContactFormListener() {
	$("#contactForm").submit(function(ev) {
		var email = $(this)
			.find("input[type='email']")
			.val();

		if (!isValidEmailAddress(email)) {
			ev.preventDefault();
			alert("Invalid email address.");
			return;
		}

		if (
			!$(this)
				.find(".g-recaptcha")
				.val()
		) {
			ev.preventDefault();
			$(this)
				.find(".invalid-g-recaptcha")
				.show();
			return;
		}
	});
}

function setButtonLoading($this) {
	var submitButton = $this.find('button[type="submit"]').not(".no-loading");

	if (submitButton.length) {
		submitButton.prop("disabled", true).html('<i class="fas fa-spinner-third fa-spin"></i>');
	}
}

function initNewsletterListener() {
	$("#newsletter").submit(function(ev) {
		ev.preventDefault();

		var email = $("#newsletter .newsletter-field").val();

		if (!isValidEmailAddress(email)) {
			alert("Invalid email address.");
		} else {
			$("#newsletterModal").modal("show");
		}
	});

	$("#newsletterForm").on("submit", function(ev) {
		if (
			!$(this)
				.find(".g-recaptcha")
				.val()
		) {
			ev.preventDefault();

			$(this)
				.find(".invalid-g-recaptcha")
				.show();

			return;
		}

		setButtonLoading($(this));
	});

	$("#newsletterModal").on("shown.bs.modal", function() {
		reSizeCaptcha("#newsletterModal ");

		var email = $("#newsletter .newsletter-field").val();

		$("#newsletterName").trigger("focus");
		$("#newsletterEmail").val(email);
	});

	$("#newsletterModal").on("hide.bs.modal", function() {
		$("#newsletterName").val("");
		$("#newsletterEmail").val("");

		grecaptcha.reset();
	});
}

function reSizeCaptcha(parent = "") {
	if ($(parent + ".g-recaptcha").length) {
		var width = $(parent + ".g-recaptcha")
			.parent()
			.width();

		if (width > 150 && width < 302) {
			var scale = (width - 5) / 302;

			$(parent + ".g-recaptcha").css("transform", "scale(" + scale + ")");
			$(parent + ".g-recaptcha").css("-webkit-transform", "scale(" + scale + ")");
		} else {
			$(parent + ".g-recaptcha").css("transform", "unset");
			$(parent + ".g-recaptcha").css("-webkit-transform", "unset");
		}
	}
}

window.captchaCallback = function(response) {
	$(".g-recaptcha").val(response);
};
